import * as z from "zod"

function zProductionOnly(val: unknown): boolean {
  // some configuration options only make sense in production
  // for example, analytics and error reporting should not produce noise during development or testing
  if (process.env["NODE_ENV"] !== "production" && val !== undefined) {
    return false
  }

  return true
}

const parsedBoolean = z.preprocess((val) => typeof val === "string" && val.toLowerCase() === "true", z.boolean())
const parsedInt = z.preprocess((val) => (typeof val === "string" ? parseInt(val) : val), z.number())

const schema = z.object({
  // NODE_ENV is a standard value https://rafaelalmeidatk.com/blog/why-you-should-not-use-a-custom-value-with-node-env
  NODE_ENV: z.enum(["development", "production", "test"]).default("production"),
  // REACT_APP_APP_URL is the public address for users
  REACT_APP_APP_URL: z
    .string()
    .refine((arg) => arg.length > 0)
    .catch("https://app.greatexpectations.io"),
  // REACT_APP_API_URL is the backend API endpoint base URL
  REACT_APP_API_URL: z
    .string()
    .refine((arg) => arg.length > 0)
    .catch("https://api.greatexpectations.io"),
  // REACT_APP_ENV is used to tag Sentry (https://docs.sentry.io/product/sentry-basics/environments) and PostHog events
  REACT_APP_ENV: z.enum(["dev", "production"]).optional().refine(zProductionOnly),
  // REACT_APP_GIT_SHA is the git commit hash of the current build
  REACT_APP_GIT_SHA: z.string().optional(),
  // REACT_APP_POSTHOG_API_KEY determines which PostHog Project to send analytics to
  REACT_APP_POSTHOG_API_KEY: z
    .string()
    .refine((arg) => arg.length > 0)
    .catch("phc_ph6ugZ1zq94dli0r1xgFg19fk2bb1EdDoLn9NZnCvRs"),
  // REACT_APP_VERBOSE_TEST_OUTPUT is a development option
  REACT_APP_VERBOSE_TEST_OUTPUT: parsedBoolean.default(false),
  // REACT_APP_USE_DEV_MODE is a development option
  REACT_APP_USE_DEV_MODE: parsedBoolean.default(false),
  // REACT_APP_USE_MOCKS is a development option
  REACT_APP_USE_MOCKS: parsedBoolean.default(false),
  // REACT_APP_JOB_POLL_INTERVAL is a development option
  REACT_APP_JOB_POLL_INTERVAL: parsedInt.default(1000),
  // REACT_APP_JOB_POLL_INTERVAL: parsedInt.default(900000),
  // REACT_APP_AGENT_STATUS_POLL_INTERVAL is a development option
  REACT_APP_AGENT_STATUS_POLL_INTERVAL: parsedInt.default(10000),
  // REACT_APP_DATA_SOURCE_IDS_POLL_INTERVAL is a development option
  REACT_APP_DATA_SOURCE_IDS_POLL_INTERVAL: parsedInt.default(10000),
  // REACT_APP_AUTH0_DOMAIN refers to the Auth0 Application Domain
  // **IMPORTANT**: The Auth0 UI does not reveal custom domains. We use a custom domain in production. It is managed in AWS.
  // Also see mercury/infrastructure/auth0/deploy_cli_config/prod_cli_config.json
  REACT_APP_AUTH0_DOMAIN: z
    .string()
    .refine((arg) => arg.length > 0)
    .catch("auth.greatexpectations.io"),
  // REACT_APP_AUTH0_CLIENT_ID refers to the Auth0 Application
  REACT_APP_AUTH0_CLIENT_ID: z
    .string()
    .refine((arg) => arg.length > 0)
    .catch("kPiBH6YWCfUm77zWOEPFRAjwV1cYLqci"),
  // REACT_APP_AUTH0_AUDIENCE refers to the OAuth2 audience https://community.auth0.com/t/what-is-the-audience/71414
  // Also see mercury/infrastructure/auth0/deploy_cli_config/prod_cli_config.json
  REACT_APP_AUTH0_AUDIENCE: z
    .string()
    .refine((arg) => arg.length > 0)
    .catch("https://api.greatexpectations.io/"),
  // REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID determines which LaunchDarkly Environment to use
  REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID: z
    .string()
    .refine((arg) => arg.length > 0)
    .catch("64138d0e0bfe2e1304e0fd1d"),
  REACT_APP_ONBOARDING_CALENDLY_LINK: z
    .string()
    .refine((arg) => arg.length > 0)
    .catch("https://calendly.com/d/cj93-cw2-ndh/gx-cloud-onboarding-support"),
})

export type Configuration = z.infer<typeof schema>

// Perform validation
// On the server, this will exit the process if validation fails
// On the client, this will throw an error if validation fails
export function validate(val: unknown): Configuration {
  return schema.parse(val)
}

export const {
  NODE_ENV,
  REACT_APP_APP_URL,
  REACT_APP_API_URL,
  REACT_APP_ENV,
  REACT_APP_GIT_SHA,
  REACT_APP_POSTHOG_API_KEY,
  REACT_APP_VERBOSE_TEST_OUTPUT,
  REACT_APP_USE_DEV_MODE,
  REACT_APP_USE_MOCKS,
  REACT_APP_JOB_POLL_INTERVAL,
  REACT_APP_AGENT_STATUS_POLL_INTERVAL,
  REACT_APP_DATA_SOURCE_IDS_POLL_INTERVAL,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID,
  REACT_APP_ONBOARDING_CALENDLY_LINK,
} = validate(process.env)
