import { getMenuCSS } from "src/ui/Menu/menuCSS"
import { createGlobalStyle, css } from "styled-components"

// AppCss must be rendered within a theme provider context
const AppCss = createGlobalStyle`
 ${({ theme }) => css`
   /* the menu is styled at this scope because it has popovers */
   ${getMenuCSS(theme)}

   .rc-virtual-list-holder-inner .ant-select-item[aria-selected="false"]:hover,
     .rc-virtual-list-holder-inner .ant-select-item[aria-selected="true"] {
     background: ${theme.colors.primaryColors.gxAccentBackground};
   }
 `};
`

export { AppCss }
