import { useFlags } from "launchdarkly-react-client-sdk"

export const featureFlags = [
  "batchDefinitionEnabled",
  "checkboxAssetCreation",
  "checkboxAssetCreationPart2",
  "customSqlExpectationsEnabled",
  "dataAssetEmptyStateEnabled",
  "databricksDataSourceSupport",
  "emailAlertEnabled",
  "forceEnableAgent",
  "genericSqlDataSourceSupport",
  "gxRunnerEnabled",
  "gxSchedulerEnabled",
  "hideAlertsEnabled",
  "hideQueryAssetEnabled",
  "maintenanceEnabled",
  "onboardingSurveyMarch24Enabled",
  "postgreSqlDataSourceSupport",
  "selfServiceAccountCreationEnabled",
  "selfServiceSignupButtonEnabled",
  "simplifyingCloudUIv0Enabled",
  "sujeEnabled",
  "sqliteDataSourceSupport",

  // User plan flags
  "teamPlanEnabled",
] as const

export type FeatureFlag = (typeof featureFlags)[number]
// we only support boolean flags currently
export type FlagSet = Record<FeatureFlag, boolean>

export function useIsFeatureEnabled(flagName: FeatureFlag): boolean {
  // TODO - send flag exposure event or property to posthog here, in the process of defining event guidelines with Tetris
  const flags = useFlags<FlagSet>()
  return Boolean(flags?.[flagName])
}
