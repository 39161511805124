/* eslint-disable react-refresh/only-export-components */ // FIXME
import { AppLink } from "src/ui/AppLink/AppLink"
import { AllThemeColorPropNames, Theme, ThemeColorName, theme } from "src/ui/themes/theme"
import styled, { css, DefaultTheme } from "styled-components"
import { Typography } from "antd"
import { LinkProps } from "antd/lib/typography/Link"

export const defaultTextColor = (theme: Theme) => theme.colors.neutralColorPalette.blacks.colorText
type TextColor = Extract<
  ThemeColorName,
  | "colorTextSecondary"
  | "colorTextTertiary"
  | "colorTextQuaternary"
  | "gxAccentMedium"
  | "gxError"
  | "white"
  | "gxPrimaryLight"
>

// prepend $ in order to make a transient prop for styled-components https://styled-components.com/docs/api#transient-props
type TextColorTransientName = `$${TextColor}`
type ThemeStandIn = {
  theme: DefaultTheme
}
type StyledProps<T> = T & ThemeStandIn

export function getTextColor({
  theme,
  ...rest
}: StyledProps<{ [key in TextColorTransientName]?: boolean }>): string | undefined {
  const [colorName] = Object.entries(rest)
    .filter(([k, v]) => v && k.startsWith("$") && AllThemeColorPropNames.includes(k.slice(1)))
    .map(([k]) => k) as TextColorTransientName[]

  switch (colorName) {
    case "$colorTextSecondary":
      return theme.colors.neutralColorPalette.blacks.colorTextSecondary
    case "$colorTextTertiary":
      return theme.colors.neutralColorPalette.blacks.colorTextTertiary
    case "$colorTextQuaternary":
      return theme.colors.neutralColorPalette.blacks.colorTextQuaternary
    case "$gxAccentMedium":
      return theme.colors.primaryColors.gxAccentMedium
    case "$gxError":
      return theme.colors.error.gxError
    case "$white":
      return theme.colors.neutralColorPalette.whites.white
    case "$gxPrimaryLight":
      return theme.colors.primaryColors.gxPrimaryLight

    default:
      return defaultTextColor(theme)
  }
}

type TransientTextColorProps = { [key in TextColorTransientName]?: boolean }

export const CaptionRegular = styled.p<TransientTextColorProps>`
  color: ${getTextColor};
  ${({ theme }) => css`
    margin: 0;
    ${theme.typography.regular.caption}
  `}
`

export const LabelRegular = styled.label<TransientTextColorProps & { $pointer?: true }>`
  color: ${getTextColor};
  ${({ $pointer }) => ($pointer ? "cursor: pointer;" : "")}
  ${({ theme }) => css`
    margin: 0;
    ${theme.typography.regular.label}
  `}
`

export const BodyRegular = styled.p<TransientTextColorProps>`
  color: ${getTextColor};
  ${({ theme }) => css`
    margin: 0;
    ${theme.typography.regular.body};
  `}
`

export const SubtitleRegular = styled.div<TransientTextColorProps>`
  color: ${getTextColor};
  ${({ theme }) => css`
    font-family: ${theme.fonts.montserrat};
    margin: 0;
    ${theme.typography.regular.subtitle}
  `}
`

export const SubtitleRegular2 = styled.div<TransientTextColorProps>`
  color: ${getTextColor};
  ${({ theme }) => css`
    ${theme.fonts.montserrat}
    ${theme.typography.regular.subtitle2}
  `}
`

export const CaptionSemiBold = styled.p<TransientTextColorProps & { $pointer?: true }>`
  color: ${getTextColor};
  ${({ theme, $pointer }) => css`
    ${$pointer ? "cursor: pointer;" : ""}
    margin: 0;
    ${theme.typography.semibold.smallLabel}
  `}
`

export const LabelSemiBold = styled.label<TransientTextColorProps>`
  color: ${getTextColor};
  ${({ theme }) => css`
    margin: 0;
    ${theme.typography.semibold.label}
  `}
`

export const SpanSemiBold = styled.span<TransientTextColorProps>`
  color: ${getTextColor};
  ${({ theme }) => css`
    margin: 0;
    ${theme.typography.semibold.label}
  `}
`

export const BodySemiBold = styled.p<TransientTextColorProps>`
  color: ${getTextColor};
  ${({ theme }) => css`
    margin: 0;
    ${theme.typography.semibold.body}
  `}
`
export const BodySemiBoldLink = styled(AppLink)<TransientTextColorProps>`
  &.ant-typography,
  .ant-typography a {
    color: ${getTextColor};
    ${({ theme }) => css`
      ${theme.typography.semibold.body}
    `}
  }
`

const standardStyledLink = css`
  color: ${getTextColor};
  text-decoration: underline;

  &:hover,
  &:active {
    text-decoration: underline;
  }
`

export const BodyRegularLink = styled(AppLink)<TransientTextColorProps>`
  &.ant-typography,
  .ant-typography a {
    ${({ theme }) => css`
      ${theme.typography.regular.body}
    `}
    ${standardStyledLink}
  }
`
// TODO: deprecate this and/or figure out a way to simplify AppLink so that it's not so complex
export const LabelLink = styled(AppLink)<TransientTextColorProps>`
  &.ant-typography,
  .ant-typography a {
    ${({ theme }) => css`
      ${theme.typography.regular.label}
    `}
    ${standardStyledLink}
  }
`

// use this simplified component for external links (instead of LabelLink)
export function LabelLink2({ children, ...props }: React.PropsWithChildren<LinkProps>) {
  return (
    <Typography.Link
      style={{
        textDecoration: "underline",
        ...theme.typography.regular.label,
      }}
      {...props}
    >
      {children}
    </Typography.Link>
  )
}

export const CaptionLink = styled(AppLink)<TransientTextColorProps>`
  &.ant-typography,
  .ant-typography a {
    ${({ theme }) => css`
      ${theme.typography.semibold.smallLabel}
    `}
    ${standardStyledLink}
  }
`

export const DisabledCaptionLink = styled(Typography.Link)<TransientTextColorProps>`
  &.ant-typography,
  .ant-typography a {
    ${({ theme }) => css`
      ${theme.typography.semibold.smallLabel}
    `}
    ${standardStyledLink}
  }
`

export const BodyLink = styled(AppLink)<TransientTextColorProps>`
  &.ant-typography,
  .ant-typography a {
    ${({ theme }) => css`
      ${theme.typography.regular.body}
    `}
    ${standardStyledLink}
  }
`

export const SubtitleSemiBold = styled.div<TransientTextColorProps>`
  color: ${getTextColor};
  ${({ theme }) => css`
    font-family: ${theme.fonts.montserrat};
    margin: 0;
    ${theme.typography.semibold.subtitle}
  `}
`

export const SubheaderSemiBold = styled.p<TransientTextColorProps>`
  color: ${getTextColor};
  ${({ theme }) => css`
    font-family: ${theme.fonts.montserrat};
    margin: 0;
    ${theme.typography.semibold.subtitle2}
  `}
`

export const H4 = styled.h4<TransientTextColorProps>`
  color: ${getTextColor};
  ${({ theme }) => css`
    font-family: ${theme.fonts.montserrat};
    margin: 0;
    ${theme.typography.semibold.h4}
  `}
`

export const H3 = styled.h3<TransientTextColorProps>`
  color: ${getTextColor};
  ${({ theme }) => css`
    font-family: ${theme.fonts.montserrat};
    margin: 0;
    ${theme.typography.semibold.h3}
  `}
`

export const H2 = styled.h2<TransientTextColorProps>`
  color: ${getTextColor};
  ${({ theme }) => css`
    font-family: ${theme.fonts.montserrat};
    margin: 0;
    ${theme.typography.semibold.h2}
  `}
`

export const H1 = styled.h1<TransientTextColorProps>`
  color: ${getTextColor};
  ${({ theme }) => css`
    font-family: ${theme.fonts.montserrat};
    margin: 0;
    ${theme.typography.semibold.h1}
  `}
`
